import { DataTransfer } from 'frontend-core';
import { CreateIngredient, IngredientActions } from './types';
import { displayError, ErrorActions } from 'redux/ducks/error';
import { ThunkResult } from 'redux/types';
import { AxiosResponse } from 'axios';
import * as errorDispatch from 'redux/ducks/error';
import * as registrationPointsDispatch from 'redux/ducks/data/registrationPoints';
import { Ingredient } from 'redux/ducks/data/registrationPoints';

export * from './types';

const transfer = new DataTransfer({ retryConfig: { retries: 3 } });

interface IPatchObject {
  op: string;
  path: string;
  value: string | any | any[] | number;
}

export function createIngredient(
  ingredient: CreateIngredient
): ThunkResult<Promise<IngredientActions | ErrorActions>, IngredientActions | ErrorActions> {
  return async (dispatch) => {
    try {
      (await transfer.post(`/foodwaste/ingredients`, ingredient)) as AxiosResponse<Ingredient>;
      await dispatch(registrationPointsDispatch.findTree());
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function updateIngredient(
  ingredientId: string,
  patchObject: IPatchObject[]
): ThunkResult<Promise<IngredientActions | ErrorActions>, IngredientActions | ErrorActions> {
  return async (dispatch) => {
    try {
      (await transfer.patch(
        `/foodwaste/ingredients/${ingredientId}`,
        patchObject
      )) as AxiosResponse<Ingredient>;
      await dispatch(registrationPointsDispatch.findTree());
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function deleteIngredient(
  id: string
): ThunkResult<Promise<IngredientActions | ErrorActions>, IngredientActions | ErrorActions> {
  return async (dispatch) => {
    try {
      (await transfer.delete(`/foodwaste/ingredients/${id}`)) as AxiosResponse<Ingredient>;
      await dispatch(registrationPointsDispatch.findTree());
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}
