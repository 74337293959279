/* eslint-disable */

import * as React from 'react';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";

// MATERIAL TABLE
import { Column } from "material-table";
import AlteredMaterialTable  from 'components/MaterialTable';

// ICONS
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// OVERWRITTEN MATERIAL TABLE COMPONENTS
import CustomTableBody from 'components/MaterialTable/components/customTableBody';
import CustomEditRow from 'components/MaterialTable/components/customEditRow';
import CustomTableAction from 'components/MaterialTable/components/customTableAction';

// MISC
import { colors, IconButton, TextField, Tooltip, withWidth } from "@material-ui/core";
import palette from 'styles/palette';
import Input from 'components/Input';

import { validate } from 'utils/validator';

import { scrollToEl } from 'utils/helpers';
import { isIE11 } from 'utils/browsers';
import { Slide } from '@material-ui/core';
import { Ingredient } from "redux/ducks/data/registrationPoints";
import { CreateIngredient } from 'redux/ducks/data/ingredients';
import { IPatchObject } from "redux/ducks/data/registrations";
import { formatMoney, formatWeight, unformat } from "utils/number-format";
import NumberInput from "components/Input/NumberInput";
import { NumberFormatValues } from "react-number-format";
import { formatMass, unformatMass } from "components/FormattedMass";
import AddIcon from "@material-ui/icons/Add";
import { WithWidth } from "@material-ui/core/withWidth";
import CustomTableRow from "components/MaterialTable/components/customTableRow";
import { columnWidthMap } from "pages/Settings/components/settings/registrations";

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = {
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    borderBottom: `1px solid ${colors.grey['200']}`,
    height: 35,
    backgroundColor: `${colors.grey['50']}`
  },
  actionsCellStyle: {
    whiteSpace: 'pre'
  }
};

type StoreProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  tableRef: React.RefObject<any>;
  registrationPointId: string;
  ingredients: Ingredient[];
  onDelete(id: string): void;
  onCreate(ingredient: CreateIngredient): void;
  onUpdate(id: string, patch: IPatchObject[]): void;
}

type IngredientTableProps = WrappedComponentProps & OwnProps & StoreProps & WithWidth;

const NEW_OR_UPDATED_ROW = 'new-or-updated-row';
const deleteTransitionDuration = 1000;

class IngredientTable extends React.Component<IngredientTableProps, {}> {
  deletedRow: any;

  // customActions: Custom actions to be added besides the default ones 'Edit' & 'Delete', on each table row
  private customActions: {
    onClick?: (event, rowData) => void;
    icon: () => any;
    tooltip?: string;
  }[];

  // whitelistedKeys: Array including properties that we want to work with. Taken from old solution.
  private whitelistedKeys: string[];
  // newRow: Variable which we can use to determine if the row we're rendering is a newly added one. (Styling purposes)
  private newRow: number | string;
  // updatedRow: Just like newRow, this is used to determine if the row we're rendering has just been updated. (Styling purposes)
  private updatedRow: number | string;

  constructor(props: IngredientTableProps) {
    super(props);

    this.customActions = [
      {
        icon: () => <AddIcon cursor='default' color={'disabled'} />,
        onClick: () => null
      },
      {
        icon: () => <VisibilityIcon color='primary' />,
        tooltip: props.intl.formatMessage({ id: 'base.deactivate' }),
        onClick: (event, rowData) => {
          const newData = Object.assign({}, rowData);
          newData.active = !newData.active;
          this.onRowUpdate(newData, rowData);
        }
      }
    ];

    this.whitelistedKeys = ['image', 'name', 'costPerkg', 'co2Perkg', 'active', 'label', 'parentId', 'registerPerItem', 'amount'];
  }

  componentDidMount(): void {
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keyup', this.onKeyUp);
  }

  /**
   * KeyUp handler: Cancels the Edit Row
   * */
  onKeyUp = (event): void => {
    if (event.which === 27) {
      // If Esc key is pressed
      const mode = this.props.tableRef.current.state.showAddRow ? 'add' : 'update';

      this.props.tableRef.current.onEditingCanceled(mode);
      this.onCancelHandler();
    }
  };

  /**
   * Takes the registration-points data coming from the endpoint, parses it,
   * and starts setting up the columns based on the fields.
   * @returns { Column[] } - array of columns that will be passed as a prop to MT
   * */
  extractColumnsFromData = (): Column<Ingredient>[] => {
    const disabledColumns = ['active'];
    const enabledColumns = this.whitelistedKeys.filter(
      (key) => disabledColumns.indexOf(key) === -1
    );

    return enabledColumns.map((key) => {
      switch (key) {
        case 'name':
          return this.setupNameColumn(key);
          break;

        case 'image':
          return this.setupImageColumn(key);
          break;

        case 'costPerkg':
          return this.setupCostColumn(key);
          break;

        case 'co2Perkg':
          return this.setupCO2PerKgColumn(key);
          break;

        case 'label':
          return this.setupLabelColumn(key);
          break;

        case 'parentId':
          return this.setupParentColumn(key);
          break;

        case 'registerPerItem':
          return this.setupRegisterPerItemColumn(key);
          break;

        case 'amount':
          return this.setupAmountColumn(key);
          break;

        default:
          return this.setupDefaultColumn(key);
      }
    });
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupDefaultColumn = (key): Column<Ingredient> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: key }),
      field: key
    };
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @param { key }
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupNameColumn = (key): Column<Ingredient> => {
    const { intl, width } = this.props;

    return {
      title: intl.formatMessage({ id: key }),
      field: key,
      cellStyle: {
        position: 'relative',
        whiteSpace: 'pre',
        paddingLeft: width === 'xs' || width === 'sm' ? '148px' : '112px',
      },
      headerStyle: {
        paddingLeft: 28,
      },
      customSort: (a, b) => {
        return a.name.localeCompare(b.name, intl.locale, { sensitivity: 'base' });
      },
      render: this.renderNameColumn,
      editComponent: (props) => this.renderNameEditMode(props, key)
    };
  };

  /**
   * Renders the data for the 'Name' column
   * @param { rowData } - row data
   * @returns { string | JSX.Element } - returns the value needed to be rendered, or the value together with an icon if it's a newly added row
   * */
  renderNameColumn = (rowData): string | JSX.Element => {
    const { intl } = this.props;
    const isNewRow = this.newRow === rowData.id;
    const transitionDuration = isNewRow ? '0.3' : '1';
    const opacityValue = isNewRow ? 1 : 0;
    const checkIconStyle = {
      transition: 'opacity ' + transitionDuration + 's ease-out 1s',
      opacity: opacityValue,
      width: 20,
      verticalAlign: 'bottom',
      marginLeft: 10
    };

    this.newRow = this.newRow === rowData.id ? undefined : this.newRow;

    return (
      <>
        {!rowData.active ? (
          <span style={{ transition: 'opacity 300ms ease-out', opacity: 0.4 }}>
            {rowData.name}
            <Tooltip
              className={'tooltip'}
              title={intl.formatMessage({ id: 'settings.ingredients.deactivated' })}
              enterTouchDelay={50}
              leaveTouchDelay={2500}
            >
              <VisibilityOffIcon
                htmlColor={'#000'}
                style={{
                  cursor: 'help',
                  width: 15,
                  height: 15,
                  verticalAlign: 'text-bottom',
                  marginLeft: 5
                }}
              />
            </Tooltip>
          </span>
        ) : (
          <span>{rowData.name}</span>
        )}
        <CheckIcon htmlColor={palette.primary1Color} style={checkIconStyle} />
      </>
    );
  };

  /**
   * Renders the custom edit component for the 'Name' column
   * @param { props } - props
   * @param { key } - the key
   * @returns { JSX.Element } - the element that should be rendered instead of the default edit component provided by MT
   * */
  renderNameEditMode = (props, key): JSX.Element => {
    const { intl } = this.props;

    return (
      <Input
        type={'text'}
        shouldValidate={(value: string) => {
          return value ? this.isValidData(props.rowData, key) && value.trim().length > 0 : false;
        }}
        required
        value={props.value}
        focusOnMount={true}
        name={`${key}_field`}
        style={{ marginTop: '-6px', height: '48px' }}
        label={intl.formatMessage({ id: `${key}` })}
        onKeyDown={(event) => this.onInputKeyDown(event, props)}
        onChange={(e: any, value: string) => props.onChange(value)}
      />
    );
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @param { key }
   * @returns { Column } - returns the value needed to be rendered, or the value together with an icon if it's a newly added row
   * */
  setupImageColumn = (key): Column<Ingredient> => {
    const { intl, width } = this.props;

    return {
      title: intl.formatMessage({ id: key }),
      field: key,
      headerStyle: {
        width: 80,
        minWidth: 80,
        textAlign: 'center',
        fontFamily: '"Roboto", "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif',
        letterSpacing: '0.024em'
      },
      cellStyle: {
        width: 80,
        minWidth: 80,
        position: 'relative',
        textAlign: 'center',
        color: colors.grey['500']
      },
      sorting: false,
      disableClick: true,
      hidden: width === 'xs' || width === 'sm',
      render: () => (
        <IconButton size='small' disableRipple style={{ backgroundColor: 'transparent', cursor: 'default' }}>
          <ShoppingCartIcon fontSize='small' />
        </IconButton>
      ),
      editable: 'never'
    };
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @param { key }
   * @returns { Column } - returns the value needed to be rendered, or the value together with an icon if it's a newly added row
   * */
  setupCostColumn = (key): Column<Ingredient> => {
    const { intl, currency, unit } = this.props;

    return {
      title: `${intl.formatMessage({ id: key })} (${currency}/${unit})`,
      field: key,
      headerStyle: {
        ...columnWidthMap.default
      },
      cellStyle: {
        ...columnWidthMap.default
      },
      editComponent: (props) => this.renderCostEditMode(props, key),
      render: (rowData) => {
        return formatMoney(rowData[key]).toString();
      }
    };
  };

  /**
   * Renders the custom edit component for the 'Cost' column
   * @param { props } - props
   * @param { key } - the key
   * @returns { JSX.Element } - the element that should be rendered instead of the default edit component provided by MT
   * */
  renderCostEditMode = (props, key): JSX.Element => {
    const { intl, currency, unit } = this.props;
    let costValue = props.value;
    let floatValue = formatMoney(costValue).value;

    return (
      <NumberInput
        value={props.value ? floatValue : props.value}
        type={'text'}
        shouldValidate={() => {
          return this.isValidData(props.rowData, key);
        }}
        component={TextField}
        allowNegative={false}
        name={`${key}_field`}
        style={{ minWidth: '88px', marginTop: '-6px', height: '48px' }}
        onValueChange={this.onCostValueChange(props)}
        label={`${intl.formatMessage({ id: `${key}` })} (${currency}/${unit})`}
        onKeyDown={(event) => this.onInputKeyDown(event, props)}
        currency
      />
    );
  };

  setupCO2PerKgColumn = (key): Column<Ingredient> => {
    return {
      title: <FormattedMessage id={key} values={{ sub: (chunks) => <sub>{chunks}</sub> }} />,
      field: key,
      headerStyle: {
        ...columnWidthMap.default
      },
      cellStyle: {
        ...columnWidthMap.default
      },
      editComponent: (props) => {
        let costValue = props.value;
        let floatValue = formatWeight(costValue);

        return (
          <NumberInput
            value={floatValue}
            type={'text'}
            style={{ minWidth: '100px', marginTop: '-6px' }}
            component={TextField}
            onChange={(e) => {
              props.onChange(unformat(e.target.value) * 1000);
            }}
            inputMode={'decimal'}
            allowNegative={false}
            min={1}
            name={`${key}_field`}
            label={<FormattedMessage id={key} values={{ sub: (chunks) => <sub>{chunks}</sub> }} />}
          />
        );
      },
      render: (rowData) => {
        return rowData[key] ? formatWeight(rowData[key]) : '-';
      }
    };
  };

  setupLabelColumn = (key): Column<Ingredient> => {
    const { intl, width } = this.props;

    return {
      title: intl.formatMessage({ id: key }),
      field: key,
      headerStyle: {
        ...columnWidthMap.small
      },
      cellStyle: {
        ...columnWidthMap.small
      },
      hidden: width === 'xs' || width === 'sm',
      render: () => <span>{intl.formatMessage({ id: 'ingredient' })}</span>,
      editable: 'never'
    };
  };

  setupParentColumn = (key): Column<Ingredient> => {
    const { width } = this.props;

    return {
      title: '',
      field: key,
      headerStyle: {
        ...columnWidthMap.default
      },
      cellStyle: {
        ...columnWidthMap.default
      },
      hidden: width === 'xs' || width === 'sm',
      sorting: false,
      render: () => <span />,
      editable: 'never'
    };
  };

  setupRegisterPerItemColumn = (key): Column<Ingredient> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: key }),
      field: key,
      cellStyle: {
        ...columnWidthMap.small
      },
      headerStyle: {
        ...columnWidthMap.small
      },
      render: () => '-',
      editable: 'never'
    };
  };

  setupAmountColumn = (key): Column<Ingredient> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: 'settings.registrationPoints.amountPerItem' }),
      field: key,
      headerStyle: {
        ...columnWidthMap.small
      },
      cellStyle: {
        ...columnWidthMap.small
      },
      editComponent: (props) => this.renderAmountEditMode(props, key),
      render: (rowData) => {
        return formatWeight(rowData[key]);
      }
    };
  };

  renderAmountEditMode = (props, key) => {
    const { intl } = this.props;
    let massFloat = formatMass(props.value);

    return (
      <NumberInput
        value={props.value ? massFloat : props.value}
        type={'text'}
        shouldValidate={() => {
          return this.isValidData(props.rowData, key);
        }}
        component={TextField}
        allowNegative={false}
        inputMode={'decimal'}
        name={`${key}_field`}
        style={{ minWidth: '100px', marginTop: '-6px' }}
        label={`${intl.formatMessage({ id: 'settings.registrationPoints.amountPerItemLabel' })}`}
        onValueChange={this.onAmountValueChange(props)}
        onKeyDown={(event) => this.onInputKeyDown(event, props)}
      />
    );
  };

  onCostValueChange = (props) => (values: NumberFormatValues) => {
    const floatValue = values.floatValue;

    props.onChange(formatMoney(floatValue, { inMajorUnit: true }).inMinorUnits);
  };

  onAmountValueChange = (props) => (values: NumberFormatValues) => {
    const massFloat = values.floatValue;

    props.onChange(unformatMass(massFloat));
  };

  /*
   *
   *
   * */
  isValidData(rowData, key) {
    const validation = validate('reason-create-request', rowData);
    return !validation.erroneousKeys.has(key);
  }

  /**
   * Checks whether the user pressed Enter or Escape, and approve or cancel the editing depending on the action
   * @param { event }
   * @param { props }
   * */
  onInputKeyDown = (event, props): void => {
    const mode = this.props.tableRef.current.state.showAddRow ? 'add' : 'update';

    if (event.which === 13) {
      if (props.rowData.name && props.rowData.name.trim().length) {
        this.props.tableRef.current.onEditingApproved(
          mode,
          props.rowData,
          this.props.tableRef.current.state.lastEditingRow
        );
        if (mode === 'update') {
          return this.onSubmitHandler(props.rowData);
        }
      }
    }
  };

  /**
   * Sets the correct VisibilityIcon icon depending on the 'active' property
   *
   * @param { props }
   * */
  setCorrectVisibilityIcon = (props) => {
    const { intl } = this.props;

    if (props.actions.length === 0) {
      return;
    }

    if (!props.data.active) {
      props.actions[1].icon = () => <VisibilityOffIcon style={{ color: colors.grey['400'] }} />;
      props.actions[1].tooltip = intl.formatMessage({ id: 'base.activate' });
    } else {
      props.actions[1].icon = () => <VisibilityIcon color={'primary'} />;
      props.actions[1].tooltip = intl.formatMessage({ id: 'base.deactivate' });
    }
  };

  /**
   * Override the default MT table row with a custom one, and render it.
   * The custom row is a copy of the original, with some small code adjustments.
   *
   * @param { props }
   * */
  overrideTableRow = (props): JSX.Element => {
    const isRowUpdatedOrNew = this.updatedRow === props.data.id || this.newRow === props.data.id;

    this.setCorrectVisibilityIcon(props);
    this.updatedRow = this.updatedRow === props.data.id ? undefined : this.updatedRow;

    return (
      <CustomTableRow
        {...props}
        id={isRowUpdatedOrNew ? NEW_OR_UPDATED_ROW : null}
      />
    );
  };

  /**
   * Override the default MT table body with a custom one, and render it.
   * This is needed to block the default behaviour that renders an Edit row at the end or beginning of the table body.
   *
   * @param { props }
   * */
  overrideTableBody = (props): JSX.Element => {
    return <CustomTableBody {...props} hideNoContentMessage />;
  };

  /**
   * Override the default MT Overlay with an empty <span> in order to 'deactivate' it
   *
   * @param { props }
   * */
  overrideOverlay = (props): JSX.Element => <span />;

  /**
   * Override the default MT table edit row with a custom one, and render it.
   *
   * @param { props }
   * */
  overrideEditRow = (props): JSX.Element => {
    let deleteConfirmationText;
    const slidingIn =
      props.data && props.data.id && props.data.id === this.deletedRow ? false : true;
    const slideDuration =
      props.data && props.data.id && props.data.id === this.deletedRow
        ? deleteTransitionDuration
        : 0;

    if (props.mode === 'delete') {
      const { intl } = this.props;

      deleteConfirmationText = intl.formatMessage(
        { id: 'settings.content.deleteConfirmation' },
        { registrationPoint: props.data.name }
      );
    }

    return (
      <Slide in={slidingIn} direction={'left'} timeout={slideDuration}>
        <CustomEditRow
          {...props}
          disableEdit={(registration: any) => !registration?.name?.trim()}
          deleteConfirmationText={deleteConfirmationText}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={this.onSubmitHandler}
          actionsWidth={'200px'}
        />
      </Slide>
    );
  };

  /**
   * Whenever an Edit row has been canceled, this callback is called
   *
   ** */
  onCancelHandler = (): void => {
    this.resetHelperGlobals();
  };

  /**
   * Whenever an Edit row has been approved (by Enter or clicking on the Check icon), this callback is called
   *
   * @param { data }
   **/
  onSubmitHandler = (data: Ingredient, mode?: string): void => {
    if (mode === 'delete') {
      this.deletedRow = data.id;
    } else {
      this.updatedRow = data.id;
    }
  };

  /*
   * Reset some helper variables. This gets called when we cancel an Edit row.
   *
   * */
  resetHelperGlobals = (): void => {
    this.deletedRow = undefined;
  };

  /**
   * Clicking on a row triggers the Edit mode on that specific row
   *
   * @param { event }
   * @param { rowData }
   * */
  onRowClick = async (event, rowData) => {
    if (
      !event.target.classList.contains('tooltip') &&
      !event.target.parentNode.classList.contains('tooltip')
    ) {
      this.props.tableRef.current.dataManager.changeRowEditing(rowData, 'update');
      this.hideAddNewRow();
      return;
    }
  };

  /**
   * Hides any existing edit rows which have the function of adding a new row
   *
   * */
  hideAddNewRow = () => {
    this.resetHelperGlobals();
    this.props.tableRef.current.setState({
      ...this.props.tableRef.current.dataManager.getRenderState(),
      showAddRow: false
    });
  };

  /**
   * Returns the patch object based on the newData and oldData params
   *
   * @param { newData }
   * @param { oldData }
   * */
  getPatchObject = (newData, oldData) => {
    return this.whitelistedKeys.reduce((ops, key) => {
      if (oldData[key] !== newData[key]) {
        ops.push({ op: 'replace', path: `/${key}`, value: newData[key] });
      }

      return ops;
    }, []);
  };

  /**
   * Runs when an Edit row for a brand new row has been 'confirmed',
   * by pressing Enter or clicking on the Check icon.
   * Here we make the call to the endpoint with the data for the new row.
   *
   * @param { newData }
   * */
  onRowAdd = async (newData) => {
    this.props.onCreate({ ...newData, registrationPointId: this.props.registrationPointId });
    this.resetHelperGlobals();
    scrollToEl(NEW_OR_UPDATED_ROW);
    return;
  };

  /**
   * Runs when an Edit row for an existing row has been 'confirmed',
   * by pressing Enter or clicking on the Check icon.
   * Here we make the call to the endpoint with the new data for the existing row.
   *
   * @param { newData }
   * */
  onRowUpdate = async (newData, oldData) => {
    return new Promise<void>(async (resolve, reject) => {
      const patch = this.getPatchObject(newData, oldData);

      if (patch.length) {
        await this.props.onUpdate(newData.id, patch);
        scrollToEl(NEW_OR_UPDATED_ROW);
      } else {
        this.updatedRow = undefined;
      }
      this.resetHelperGlobals();
      resolve();
    });
  };

  /**
   * Runs when we confirmed deletion on an existing row.
   * Here we make the call to the endpoint with the data for the row we want to delete.
   *
   * @param { newData }
   * */
  onRowDelete = async (data) => {
    await new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        this.props.onDelete(data.id);
        this.updatedRow = undefined;
        this.resetHelperGlobals();
        resolve();
      }, deleteTransitionDuration - 200);
    });
  };

  render() {
    const { ingredients } = this.props;

    return (
      <AlteredMaterialTable
        tableRef={this.props.tableRef}
        // @ts-ignore
        columns={this.extractColumnsFromData()}
        data={ingredients}
        components={{
          Header: () => null,
          Toolbar: () => null,
          Pagination: () => null,
          Body: this.overrideTableBody,
          Row: this.overrideTableRow,
          OverlayLoading: this.overrideOverlay,
          EditRow: this.overrideEditRow
        }}
        actions={this.customActions}
        // @ts-ignore
        options={{ ...materialTableOptions, pageSize: ingredients.length }}
        onRowClick={this.onRowClick}
        editable={{
          onRowAdd: this.onRowAdd,
          onRowUpdate: this.onRowUpdate,
          onRowDelete: this.onRowDelete
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.settings.currency,
  unit: state.settings.unit
});

export default withWidth()(
  connect<StoreProps, unknown, OwnProps>(
    mapStateToProps,
    null
  )(injectIntl(IngredientTable))
);
